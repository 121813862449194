<template>
  <div class="select-items-container">
    <NavHeader
      :title="$t('screens.order.headerTitle')"
      :subtitle="$t('screens.order.headerSubtitle', { tableName })"
      @onBackClick="goBack"
    />
    <LayoutCard
      :loading="isLoading"
      :title="hasOrderItems ? $t('screens.order.pickItemsTitle') : undefined"
      :subtitle="
        hasOrderItems ? $t('screens.order.pickItemsSubtitle') : undefined
      "
    >
      <div v-if="hasOrderItems" class="pt-1">
        <div
          v-for="(item, key) in unpaidOrderItems"
          :key="item.id"
          class="d-flex flex-row"
          :class="{ 'pt-4': key > 0 }"
          @click="itemClick(item)"
        >
          <v-checkbox
            :key="item.id"
            v-model="selectedOptions"
            :ripple="false"
            :indeterminate="item.isPaid || item.otherSelected"
            :disabled="item.isPaid || item.otherSelected"
            :value="item.id"
            hide-details
            :data-cy="`order-item-${key}`"
            class="order-item-checkbox"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            indeterminate-icon="icon-checkbox-indeterminate"
            @change="selectOptions"
          />
          <Checkbox
            class="order-item pt-1"
            :item-key="key"
            :name="item.name"
            :price="item.price"
            :modifiers="item.modifiers"
            :is-paid="item.isPaid"
            :show-quantity="false"
            hide-modifiers
          />
        </div>
      </div>
      <StateScreen
        v-else
        :title="$t('screens.order.emptyStateTitle')"
        :subtitle="$t('screens.order.emptyStateSubtitle')"
        :image="require(`@/assets/images/states/empty.svg`)"
      />
    </LayoutCard>
    <footer>
      <Button
        v-if="hasOrderItems"
        dark
        :disabled="!selectedAmount"
        data-cy="continue-to-pay"
        class="d-flex justify-space-between"
        @click="navigateToPayment"
      >
        <span>{{ $t('screens.order.continueToPayCta') }}</span>
        <span>{{ selectedAmountFormatted }}</span>
      </Button>
    </footer>
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import NavHeader from '@/components/Navigation/NavHeader.vue';
import Checkbox from '@/components/Orders/OrderItem.vue';
import StateScreen from '@/components/StateScreen.vue';
import Button from '@/components/Button.vue';

export default {
  components: {
    LayoutCard,
    NavHeader,
    Checkbox,
    StateScreen,
    Button,
  },
  data() {
    return {
      isLoading: false,
      selectedOptions: [],
      uniqueOrderNumber: null,
      otherSelectedOptions: [],
      fetchOrdersInterval: null,
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    currentRouteName() {
      return this.$route.name;
    },
    tableName() {
      return this.$store.state.table.name;
    },
    hasOrderItems() {
      return this.orderItems.length > 0;
    },
    orderStatusClosed() {
      return this.order.status === 'closed';
    },
    order() {
      return this.$store.getters.getPOSOrderByUniqueOrderNumber(
        this.uniqueOrderNumber
      );
    },
    orderItems() {
      if (!this.order) {
        return [];
      }
      return (
        this.order.items.map(item => {
          return {
            id: item.id,
            name: item.name,
            modifiers: item.modifiers,
            price: item.price,
            isPaid:
              item.is_paid || this.order.is_paid || this.orderStatusClosed,
            otherSelected: this.otherSelectedOptions.includes(item.id),
          };
        }) || []
      );
    },
    unpaidOrderItems() {
      return this.orderItems.filter(item => !item.isPaid);
    },
    selectedAmount() {
      const selectedProducts = this.orderItems.filter(item => {
        return this.selectedOptions.includes(item.id) && !item.isPaid;
      });

      return selectedProducts.reduce((acc, item) => {
        return (
          acc +
          item.price +
          item.modifiers.reduce((acc, modifier) => {
            return acc + modifier.price;
          }, 0)
        );
      }, 0);
    },
    selectedAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.selectedAmount);
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.$store.dispatch('fetchSessionData', {
      session: this.$route.params.session,
    });

    await this.$store.dispatch('subscribeToPusherChannels');

    const uniqueOrderNumber = this.$route.query.orderNumber;
    this.uniqueOrderNumber = uniqueOrderNumber;

    const order = this.$store.getters.getPOSOrderByUniqueOrderNumber(
      this.uniqueOrderNumber
    );

    if (!order) {
      await this.$store.dispatch('fetchOrdersFromPOS');
    }

    await this.fetchPreviouslySelectedOptions();

    window.addEventListener(
      'order-items-selected',
      this.fetchPreviouslySelectedOptions
    );

    this.initiateFetchOrdersInterval();

    this.isLoading = false;

    document.onvisibilitychange = () => {
      // Deselect all items if user leaves from this page
      const hidden = document.visibilityState === 'hidden';
      const selectItemsPage = this.currentRouteName === 'SelectItems';

      if (hidden && selectItemsPage) {
        this.deselectAllOptions();
      }
    };
  },
  beforeDestroy() {
    window.removeEventListener(
      'order-items-selected',
      this.fetchPreviouslySelectedOptions
    );

    clearInterval(this.fetchOrdersInterval);
  },
  methods: {
    navigateToPayment() {
      this.$router.push({
        name: 'Pay',
        params: {
          session: this.session,
          order: this.order.unique_order_number,
        },
        query: {
          payForSelectedItems: true,
          ref: 'select-items',
        },
      });
    },
    goBack() {
      this.$router.go(-1);
      this.deselectAllOptions();
    },
    async selectOptions() {
      const response = await this.$store.dispatch('selectOrderItems', {
        uniqueOrderNumber: this.uniqueOrderNumber,
        selectedOptions: this.selectedOptions,
      });

      if (response.data.cannot_select_order_items) {
        this.selectedOptions = this.selectedOptions.filter(selectedOption => {
          return !response.data.cannot_select_order_items.includes(
            selectedOption
          );
        });
      } else if (response.data.message === 'Order has discount applied') {
        this.$store.dispatch('showToastInstantly', {
          title: this.$t('components.toast.discountAppliedToastTitle'),
          description: this.$t(
            'components.toast.discountAppliedToastDescription'
          ),
          isError: true,
        });
        this.selectedOptions = [];
      }
    },
    async deselectAllOptions() {
      try {
        this.selectedOptions = [];
        await this.axios.post(
          `/orders/select-order-items/${this.$store.state.session}`,
          {
            unique_order_number: this.uniqueOrderNumber,
            order_items: [],
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async fetchPreviouslySelectedOptions() {
      const selectedOrderItems = await this.$store.dispatch(
        'fetchSelectedOrderItems',
        {
          uniqueOrderNumber: this.uniqueOrderNumber,
        }
      );
      this.selectedOptions = selectedOrderItems.user_selected;
      this.otherSelectedOptions = selectedOrderItems?.others_selected;
    },
    initiateFetchOrdersInterval() {
      // While user is in pay&go order page, we should repeatedly fetch order (for updates)
      // each 15 seconds.
      if (
        this.hasOrderItems &&
        this.fetchOrdersInterval === null &&
        !this.orderStatusClosed
      ) {
        this.fetchOrdersInterval = setInterval(() => {
          this.$store.dispatch('fetchOrdersFromPOS');
        }, 15000);
      }
    },
    itemClick(item) {
      if (item.otherSelected) {
        this.$store.dispatch('showToastInstantly', {
          title: this.$t('components.toast.itemSelectedByOtherToastTitle'),
          description: this.$t(
            'components.toast.itemSelectedByOtherToastDescription'
          ),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-items-container {
  background-color: $color-background-gray !important;
  display: flex;
  flex-flow: column;
  min-height: 100%;
  padding-bottom: 100px;
}

.v-input--selection-controls {
  margin-top: 0 !important;
}

.order-item-checkbox::v-deep {
  .icon-checkbox-on {
    background-image: url('../../assets/images/icons/checkbox-on.svg');
  }
  .icon-checkbox-off {
    background-image: url('../../assets/images/icons/checkbox-off.svg');
  }
  .icon-checkbox-indeterminate {
    background-image: url('../../assets/images/icons/checkbox-indeterminate.svg');
  }
}

footer {
  position: fixed;
  width: 100%;
  max-width: $max-app-width;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  padding: 2em 1em calc(2em + env(safe-area-inset-bottom)) 1em;
  bottom: 0px;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(0, 212, 255, 0) 100%
  );
}
</style>
