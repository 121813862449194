<template>
  <div
    ref="container"
    v-click-outside="closeSheet"
    class="menu-item__sheet"
    :style="containerStyle"
  >
    <div class="menu-item-sheet__close-btn-wrapper" @click="closeSheet">
      <img
        class="menu-item-sheet__close-btn"
        :src="require(`@/assets/images/icons/close-item-icon.svg`)"
      />
    </div>
    <div
      v-scroll.self="onScroll"
      class="menu-item-sheet__content"
      :class="{
        'menu-item-sheet__content--out-of-stock':
          !in_stock || displayAsOutOfStock,
      }"
    >
      <div class="menu-item-sheet__top-collapsing-name-wrapper">
        <div class="top-collapsing-name tp-text-subtitle">
          <v-container>{{ $t(name) }}</v-container>
        </div>
      </div>
      <div v-if="photo" class="menu-item-sheet__cover">
        <LazyImage
          :img="photo"
          :lazy-img="thumbnail"
          :no-blur="true"
        ></LazyImage>
      </div>
      <div
        class="menu-item-sheet-content__hideable-wrapper"
        :class="{
          'menu-item-sheet-content__hideable-wrapper--no-image': !photo,
          'menu-item-sheet-content__hideable-wrapper--controls-enabled': displayControls,
          'menu-item-sheet-content__hideable-wrapper--controls-with-remove-enabled':
            displayControls && currentItemCountInCart > 0,
        }"
      >
        <LayoutCard :type="`item-header`">
          <h2
            v-intersect="{
              handler: onSheetProductNameIntersect,
              options: {
                threshold: [0.1],
              },
            }"
            class="menu-item-sheet__title tp-title-bold"
          >
            {{ $t(name) }}
          </h2>
          <div
            v-if="description"
            class="menu-item-sheet__description tp-text-body-gray pt-3"
            v-html="$t(descriptionComputed)"
          ></div>
          <dish-tags-sheet
            v-if="dishTagsDefaultAndCustomCombined.length > 0"
            class="pt-3"
            :tags="dishTagsDefaultAndCustomCombined"
          ></dish-tags-sheet>

          <div v-if="!hasVariations || popular" class="d-flex flex-row align-center pt-4">
            <price
              v-if="!hasVariations"
              class="mr-2"
              :price="priceComputed"
              :old-price="oldPriceComputed"
              :bigger="true"
              :custom-font-family="
                $store.getters.customizationMenuItemsPriceFontFamily ||
                  $store.getters.customizationMenuFontOverrideFamily
              "
              :custom-font-weight="
                $store.getters.customizationMenuItemsPriceFontWeight
              "
              :custom-font-style="
                $store.getters.customizationMenuItemsPriceFontStyle
              "
            ></price>
            <popular-badge v-if="popular" :bigger="true"></popular-badge>
          </div>
          <div
            v-if="loyaltyPricingEnabled && loyalty_price"
            class="d-flex flex-row align-center pt-1"
          >
            <loyalty-price
              v-if="!hasVariations"
              :price="loyalty_price"
              :text="$store.state.restaurant.loyaltyPricingText"
              :custom-font-family="
                $store.getters.customizationMenuItemsPriceFontFamily ||
                  $store.getters.customizationMenuFontOverrideFamily
              "
              :custom-font-weight="
                $store.getters.customizationMenuItemsPriceFontWeight
              "
              :custom-font-style="
                $store.getters.customizationMenuItemsPriceFontStyle
              "
              :bigger="true"
            ></loyalty-price>
          </div>
          <loyalty-pricing-disclaimer v-if="loyaltyPricingEnabled" />
          <div
            v-if="!in_stock || displayAsOutOfStock"
            class="text-subtitle-2 pt-5"
          >
            {{ $t('components.menuItem.outOfStock') }}
          </div>
        </LayoutCard>
        <LayoutCard v-if="hasVariations" rounded>
          <div class="tp-text-subtitle">
            {{ $t('components.menuItem.chooseVariation') }}
          </div>
          <VariationList
            :variations="variations"
            @on-change="selectVariation"
          />
        </LayoutCard>
        <OptionGroup
          v-for="(optionGroup, index) in option_groups"
          :key="`option-groups-${index}`"
          v-bind="{
            ...optionGroup,
            menuItemPrice: priceComputed,
            menuItemLoyaltyPrice: loyalty_price,
            isCategoryItemsAvailable,
          }"
          :selection="optionsSelection[index].options"
          @optionSelectionChange="optionSelectionChange"
        ></OptionGroup>
      </div>
    </div>

    <div
      v-if="displayControls"
      class="menu-item-sheet__controls-wrapper container"
      :class="{
        'pb-5': currentItemCountInCart === 0,
      }"
    >
      <div class="menu-item-sheet-controls__add">
        <QuantitySelector
          :loading="loading"
          @changeSelectedQuantity="quantity => (selectedQuantity = quantity)"
        ></QuantitySelector>
        <Button
          :loading="loading"
          data-cy="add-to-order"
          :disabled="!allowAddToCart || loading"
          :background-color="
            $store.getters.customizationMenuItemAddToCardButtonColor
          "
          @click="addItemToCart"
        >
          <div class="menu-item-sheet-controls__add-btn">
            <div>
              {{ $t('components.menuItem.addToOrder') }}
            </div>
            <div>
              {{ priceToOrderFormatted }}
            </div>
          </div>
        </Button>
      </div>
      <div
        v-if="currentItemCountInCart > 0"
        class="menu-item-sheet-controls__remove tp-text-body-2"
        :class="{
          'mb-5': currentItemCountInCart > 0,
        }"
        @click="removeItemsFromCart"
      >
        <v-progress-circular
          v-if="removeLoading"
          indeterminate
          :size="15"
          :width="2"
        />
        <span v-else
          >{{ $t('components.menuItem.remove') }} ({{
            currentItemCountInCart
          }})</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Price from '@/components/MenuItem/Price.vue';
import LoyaltyPrice from '@/components/MenuItem/LoyaltyPrice.vue';
import PopularBadge from '@/components/MenuItem/PopularBadge.vue';
import DishTagsSheet from '@/components/MenuItem/DishTagsSheet.vue';
import LoyaltyPricingDisclaimer from '@/components/MenuItem/LoyaltyPricingDisclaimer';
import OptionGroup from '@/components/MenuItem/Options/OptionGroup.vue';
import QuantitySelector from '@/components/MenuItem/Order/QuantitySelector.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import LazyImage from '@/components/LazyImage.vue';
import Button from '@/components/Button.vue';
import { normalizeNewLines } from '@/utils/strings';
import VariationList from './VariationList.vue';

export default {
  components: {
    PopularBadge,
    Price,
    LoyaltyPrice,
    DishTagsSheet,
    LoyaltyPricingDisclaimer,
    OptionGroup,
    LayoutCard,
    LazyImage,
    Button,
    QuantitySelector,
    VariationList,
  },
  props: {
    id: Number,
    name: String,
    description: String,
    photo: String,
    thumbnail: String,
    lqip: String,
    popular: Boolean,
    price: Number,
    discounted_price: [Number, Boolean],
    loyalty_price: [Number, Boolean],
    tags: Array,
    restaurant_tags: Array,
    is_special_deal: [Boolean],
    variations: Array,
    fullWidthInfo: Boolean,
    in_stock: Boolean,
    option_groups: Array,
    category: String,
    categoryHash: String,
  },
  data() {
    return {
      sheet: true,
      displayAsOutOfStock: false,
      hasVariations: this.variations.length > 0,
      stickyName: false,
      selectedQuantity: 1,
      loading: false,
      removeLoading: false,
      containerStyle: {},
      optionsSelection: this.option_groups.map(optionGroup => {
        return {
          id: optionGroup.id,
          options: optionGroup.options.map(option => {
            return {
              id: option.id,
              selected:
                option.is_default && optionGroup.type === 'single'
                  ? true
                  : false,
            };
          }),
        };
      }),
      selectedVariation: null,
    };
  },
  computed: {
    descriptionComputed() {
      return normalizeNewLines(this.description);
    },
    hasLoyaltyProgram() {
      return this.$store.getters.userHasRestaurantLoyaltyProgram;
    },
    priceComputed() {
      if (this.selectedVariation) {
        if (this.hasLoyaltyProgram && this.selectedVariation.loyalty_price) {
          return this.selectedVariation.loyalty_price;
        }

        return this.selectedVariation.price;
      }

      if (this.hasLoyaltyProgram && this.loyalty_price) {
        return this.loyalty_price;
      }

      return this.discounted_price !== false
        ? this.discounted_price
        : this.price;
    },
    oldPriceComputed() {
      return this.price;
    },
    loyaltyPricingEnabled() {
      return (
        this.$store.state.restaurant.loyaltyPricingEnabled &&
        (this.loyalty_price ||
          this.variations.filter(function(variation) {
            return variation.loyalty_price;
          }).length > 0)
      );
    },
    priceToOrderFormatted() {
      const price =
        (this.priceComputed + this.optionSelectionsMarkup) *
        this.selectedQuantity;

      return this.$store.getters.getFormattedPrice(price);
    },
    currentItemCountInCart() {
      let count = 0;
      this.$store.state.cart.items.forEach(cartItem => {
        if (cartItem.item_id === this.id) {
          count += 1 * cartItem.quantity;
        }
      });
      return count;
    },
    optionSelectionsMarkup() {
      let total = 0;
      this.optionGroupsWithSelectedInfo.forEach(optionGroup => {
        optionGroup.options.forEach(option => {
          if (option.selected) {
            if (this.hasLoyaltyProgram && option.loyalty_markup) {
              total += option.loyalty_markup;
            } else if (option.markup) {
              total += option.markup;
            }
          }
        });
      });

      return total;
    },
    displayControls() {
      return (
        this.$store.getters.functionalityOrderEnabled &&
        this.in_stock &&
        !this.displayAsOutOfStock &&
        !this.$store.getters.ordersTemporarilyDisabled
      );
    },
    allowAddToCart() {
      const missingSelectedOptionGroups = this.optionsSelection.filter(
        (optionGroup, key) => {
          const optionGroupData = this.option_groups[key];
          const groupSelectionCount = optionGroup.options.filter(option => {
            return option.selected;
          }).length;
          return !(
            (optionGroupData.type === 'single' && groupSelectionCount === 1) ||
            (optionGroupData.type === 'multiple' &&
              groupSelectionCount >= optionGroupData.min_choices &&
              groupSelectionCount <= optionGroupData.max_choices)
          );
        }
      );
      return missingSelectedOptionGroups.length === 0;
    },
    optionGroupsWithSelectedInfo() {
      return this.option_groups.map((optionGroup, groupKey) => {
        return {
          ...optionGroup,
          // TODO optionKey is used instead of option.id because option.id is not unique
          options: optionGroup.options.map((option, optionKey) => {
            const optionSelected = this.optionsSelection[groupKey].options[
              optionKey
            ].selected;
            return {
              ...option,
              selected: optionSelected,
            };
          }),
        };
      });
    },
    dishTagsDefaultAndCustomCombined() {
      return [...this.tags, ...this.restaurant_tags];
    },
    isCategoryItemsAvailable() {
      const currentCategory = this.$store.getters.categories.filter(
        category => {
          return this.categoryHash === category.hash;
        }
      )[0];

      if (currentCategory) {
        return (
          !currentCategory.availability.enabled ||
          (currentCategory.availability.enabled &&
            currentCategory.availability.isAvailable)
        );
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.matchHeight();

    this.$store.dispatch('sendAnalyticsEvent', {
      event: 'menu_item_sheet_opened',
      item: this.name,
      event_origin: this.$store.state.searchOpen ? 'search' : 'menu',
    });

    let scrolled = false;
    let scrolledToBottom = false;
    this.$el
      .querySelector('.menu-item-sheet__content')
      .addEventListener('scroll', e => {
        if (e.target.scrollTop > 50 && !scrolled) {
          this.$store.dispatch('sendAnalyticsEvent', {
            event: 'menu_item_sheet_scrolled',
            item: this.name,
          });
          scrolled = true;
        }
        if (
          e.target.scrollTop + e.target.clientHeight + 10 >=
            e.target.scrollHeight &&
          !scrolledToBottom
        ) {
          this.$store.dispatch('sendAnalyticsEvent', {
            event: 'menu_item_sheet_scrolled_to_bottom',
            item: this.name,
          });
          scrolledToBottom = true;
        }
      });

    this.displayAsOutOfStock = !this.isCategoryItemsAvailable;
  },
  methods: {
    matchHeight() {
      const heightString = this.$refs.container.clientHeight + 'px';
      this.containerStyle = {
        height: heightString,
      };
    },
    closeSheet() {
      this.$store.dispatch('closeMenuItem');
    },
    onSheetProductNameIntersect(entries) {
      if (entries[0].intersectionRatio < 0.1) {
        this.stickyName = true;
      } else {
        this.stickyName = false;
      }
    },
    onScroll(e) {
      const collapsingTitle = e.target.querySelector(
        '.menu-item-sheet__top-collapsing-name-wrapper'
      );

      if (this.stickyName) {
        collapsingTitle.classList.add('sticky');
      } else {
        collapsingTitle.classList.remove('sticky');
      }
    },
    optionSelectionChange({ optionGroupId, selection }) {
      this.optionsSelection = this.optionsSelection.map(optionGroup => {
        return {
          id: optionGroup.id,
          options:
            optionGroupId === optionGroup.id ? selection : optionGroup.options,
        };
      });
    },
    async addItemToCart() {
      this.loading = true;

      const optionsGroupsWithSelections = this.optionsSelection.map(
        optionGroup => {
          return {
            id: optionGroup.id,
            options: optionGroup.options
              .filter(option => {
                return option.selected;
              })
              .map(option => {
                return {
                  id: option.id,
                  quantity: 1,
                };
              }),
          };
        }
      );

      try {
        const result = await this.$store.dispatch('addCartItem', {
          menuItemId: this.id,
          quantity: this.selectedQuantity,
          optionGroups: optionsGroupsWithSelections,
          variationId: this.selectedVariation
            ? this.selectedVariation.id
            : null,
        });
        this.loading = false;
        if (result !== false) {
          this.closeSheet();
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async removeItemsFromCart() {
      try {
        this.removeLoading = true;
        await this.$store.dispatch('removeMenuItemFromCart', this.id);
        this.closeSheet();
      } catch (error) {
        this.removeLoading = false;
      }
    },
    selectVariation(variation) {
      this.selectedVariation = variation;
    },
  },
};
</script>

<style lang="scss">
.menu-item {
  &__sheet {
    min-height: 40vh;
    min-height: calc((var(--app-height) - calc(var(--app-height) * 0.4)) * 1px);
    max-height: 70vh;
    max-height: calc((var(--app-height) - 80) * 1px);
    background-color: $color-background-gray !important;
    border-radius: 5px 5px 0 0 !important;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 220;
    overscroll-behavior-y: contain;
    overflow-y: auto;
    padding-bottom: 40px;

    @media screen and (min-width: $max-app-width) {
      left: null;
      max-width: $max-app-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.menu-item-sheet {
  height: 100%;
  &__top-collapsing-name-wrapper {
    position: absolute;
    width: 100%;
    top: -54px;
    overflow: hidden;

    &.sticky {
      top: 0;

      .top-collapsing-name {
        position: fixed;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
      }
    }

    .top-collapsing-name {
      width: 100%;
      position: absolute;
      z-index: 44;
      left: 0;
      height: 54px;
      background: white;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      border-radius: 5px 5px 0 0;

      .container {
        padding-left: 16px;
      }
    }
  }

  &__cover {
    width: 100%;
    box-shadow: inset -50px 0px 100px -60px #282a2d;
    max-height: 40vh;
    img {
      object-fit: cover;
      width: 100%;
      aspect-ratio: 1.5;
      object-position: center;
      max-height: inherit;
    }
  }

  &__exit-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 10px 0;
  }

  &-content__hideable-wrapper {
    z-index: 20;
    position: relative;
    margin-top: -20px;

    &--no-image {
      margin-top: 0;
    }

    &--controls-enabled {
      padding-bottom: 80px;
    }

    &--controls-with-remove-enabled {
      padding-bottom: 142px;
    }
  }

  &__title {
    max-width: 90%;
    font-family: var(--menu-font-family) !important;
    font-weight: var(--menu-items-font-weight) !important;
    font-size: var(--menu-items-font-size) !important;
    font-style: var(--menu-items-font-style) !important;
    text-transform: var(--menu-items-text-transform) !important;
    line-height: var(--menu-items-title-line-height);
    letter-spacing: var(--menu-items-title-letter-spacing) !important;
  }

  &__description {
    color: gray;
    font-family: var(--menu-item-description-font-family) !important;
    font-style: var(--menu-item-description-font-style) !important;
    letter-spacing: var(--menu-item-description-letter-spacing) !important;
    white-space: pre-line;
  }

  &__content {
    &--out-of-stock {
      opacity: 0.4;
    }
    /* Force scroll */
    height: 101%;

    border-radius: 5px 5px 0 0;
    position: relative;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -webkit-overflow-scrolling: touch;
  }

  &__close-btn-wrapper {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
  }

  &__close-btn {
    background-color: rgba(158, 158, 158, 0.7);
    border-radius: 32px;
    position: fixed;
    z-index: 50;
    width: 30px;
  }

  &__controls-wrapper {
    position: fixed;
    bottom: 0;
    z-index: 100;
    min-height: 72px;
    padding: 2em 1em calc(1em + env(safe-area-inset-bottom)) 1em;
    background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(0, 212, 255, 0) 100%
  );
  }
}

.menu-item-sheet-controls {
  &__add {
    display: grid;
    grid-template-columns: 35% 55%;
    gap: 10%;
  }
  &__remove {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    border: 1px solid $color-red;
    color: $color-red;
    padding: 8px 0;
    margin-top: 15px;
    background: white;
  }
}

.loyalty-pricing-disclaimer {
  color: gray;
}

.v-image__image--preload {
  filter: blur(10px);
}

.menu-item-content {
  position: relative;

  &__out-of-stock-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(198 198 198 / 55%);
    z-index: 45;
    border-radius: 5px 5px 0 0;
  }
}

.menu-item-sheet-controls {
  &__add-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
}
</style>
