var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"splash-loader-screen"},[_c('lottie-vue-player',{staticStyle:{"width":"120px"},attrs:{"src":"/assets/animations/greet-logo.json","autoplay":"","loop":""}})],1):_vm._e(),(_vm.menuLoaded)?_c('div',{staticClass:"menu",class:{
      'menu--controls-enabled':
        _vm.$store.getters.functionalityOrderEnabled ||
        _vm.$store.getters.functionalityPayGoEnabled,
    },attrs:{"data-app":"","data-cy":"menu-screen"}},[_c('MenuHeader',{attrs:{"show-back":_vm.showBack,"on-back":_vm.goBack}}),_c('div',{attrs:{"id":"categories"}},[(_vm.$store.getters.quickAccessMenu.length > 0)?_c('LayoutCard',{attrs:{"id":"quick-access-menu-block","hide-background":!!_vm.$store.getters.customizationMenuHideLayoutCards,"use-custom-background-color":true}},[(!_vm.$store.getters.customizationHideQuickAccessMenuTitle)?_c('div',{staticClass:"quick-access-menu-title tp-text-title-bold mb-5",class:{
            'quick-access-menu-title--center':
              _vm.$store.getters.customizationMenuCategoriesCenter,
            'quick-access-menu-title--with-background':
              _vm.$store.getters.customizationMenuCategoriesBackgroundColor,
          }},[(_vm.$store.getters.customizationQuickAccessMenuTitleText)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.$store.getters.customizationQuickAccessMenuTitleText))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('screens.menu.quickAccessTitle')))])]):_vm._e(),_c('QuickAccessMenu')],1):_vm._e(),_c('special-deals-category'),(_vm.fetchingMenu)?_c('div',{staticClass:"text-center"},[_c('Spinner',{staticClass:"mt-10",attrs:{"color":_vm.customSpinnerColor,"stroke-width":2,"width":40,"height":40}})],1):_c('div',_vm._l((_vm.$store.getters.categoriesWithBanners),function(category,key){return _c('LayoutCard',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: [0],
              rootMargin: '-30% 0px -69% 0px',
            },
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: [0],\n              rootMargin: '-30% 0px -69% 0px',\n            },\n          }"}],key:key,attrs:{"id":`category-${category.hash}`,"hide-background":!!_vm.$store.getters.customizationMenuHideLayoutCards ||
              (!!_vm.$store.getters.customizationMenuHideLayoutCardsForBanners &&
                category.type === 'banner'),"blur":!_vm.isDrinkingAge && category.age_restricted,"hash":category.hash,"is-banner":category.type === 'banner',"use-custom-background-color":true,"compact":category.type === 'banner'},scopedSlots:_vm._u([(!_vm.isDrinkingAge && category.age_restricted)?{key:"actions",fn:function(){return [_c('DrinkingAgeOverlay',{attrs:{"visible":!_vm.isDrinkingAge}})]},proxy:true}:null],null,true)},[_c('category',{attrs:{"category":category,"index":key}})],1)}),1),_c('MenuControls')],1),_c('more-information'),_c('Transition',{attrs:{"name":"slide-from-bottom"}},[(_vm.isMenuItemSheetOpen)?_c('MenuItemSheet',_vm._b({},'MenuItemSheet',_vm.openedMenuItem,false)):_vm._e()],1),_c('Transition',{attrs:{"name":"opacity"}},[(_vm.isMenuItemSheetOpen)?_c('div',{staticClass:"menu-item-sheet-overlay"}):_vm._e()]),_c('Transition',{attrs:{"name":"slide-from-bottom"}},[(_vm.ordersEnabled && _vm.selectedOrder)?_c('OrderInfo',_vm._b({},'OrderInfo',_vm.selectedOrder,false)):_vm._e()],1),_c('Transition',{attrs:{"name":"opacity"}},[(_vm.ordersEnabled && _vm.selectedOrder)?_c('div',{staticClass:"backdrop"}):_vm._e()]),_c('Transition',{attrs:{"name":"opacity"}},[(_vm.showScrollUp)?_c('ScrollUpButton',{attrs:{"controls-enabled":_vm.controlsEnabled}}):_vm._e()],1)],1):(_vm.loadingFailure)?_c('SplashScreen',{attrs:{"status":_vm.status,"failure":_vm.loadingFailure,"loading-screen-customizations":_vm.loadingScreenCustomizations}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }