const initialState = {
  externalWallets: [],
};

export default {
  state: initialState,
  mutations: {
    setExternalWallets(state, externalWallets) {
      state.externalWallets = externalWallets;
    },
  },
  actions: {
    setExternalWallets({ commit }, externalWallets) {
      commit('setExternalWallets', externalWallets);
    },
    sendStripeIntegrationDetails({ getters }) {
      const currencyCode = getters.getCurrencyCode;
      postMessageToWebview({
        stripePublishableKey: process.env.VUE_APP_STRIPE_PK,
        walletProps: {
          googlePay: {
            testEnv: process.env.VUE_APP_ENVIRONMENT !== 'production',
            merchantCountryCode: 'LT',
            currencyCode,
          },
        },
      });
    },
    sendStripeClientSecret(_, clientSecret) {
      postMessageToWebview({
        stripeClientSecret: clientSecret,
      });
    },
    subscribeToExternalWallets({ dispatch }) {
      document?.addEventListener(
        'message',
        event => {
          const data = JSON.parse(event.data);

          if (data?.externalWallets) {
            dispatch('setExternalWallets', data.externalWallets);
          }
        },
        false
      );
    },
    subscribeToExternalPaymentStatus(_, { onSuccess, onError }) {
      document?.addEventListener(
        'message',
        event => {
          const data = JSON.parse(event.data);
          const { paymentStatus } = data;
          if (paymentStatus === 'success') {
            onSuccess();
          } else if (paymentStatus === 'error') {
            onError();
          }
        },
        false
      );
    },
    openTerminalBackOffice() {
      postMessageToWebview('openTerminalBackOffice');
    },
    generateNewKioskSession() {
      postMessageToWebview('generateNewKioskSession');
    },
    subscribeToFetchMenu({ dispatch }) {
      // Messages from RN webview
      document?.addEventListener(
        'message',
        event => {
          const data = JSON.parse(event.data);

          if (data?.forceFetchMenu) {
            dispatch('forceFetchMenu');
          }
        },
        false
      );
    },
    subscribeToKioskMessages({ dispatch }) {
      // Messages from electron wrapper
      window?.addEventListener(
        'message',
        event => {
          try {
            const data = JSON.parse(event?.data);
            if (data?.message === 'setKioskConfigData') {
              dispatch('setKioskConfigData', data.data);
            } else if (data?.message === 'forceFetchMenu') {
              dispatch('forceFetchMenu');
            }
          } catch (error) {
            console.error('Error parsing kiosk message', error);
          }
        },
        false
      );
    },
    subscribeToTouchEvents() {
      window?.addEventListener(
        'click',
        () => {
          postMessageToWebview({ message: 'iframeClicked' });
        },
        false
      );
      window?.addEventListener(
        'touchend',
        () => {
          postMessageToWebview({ message: 'iframeClicked' });
        },
        false
      );
      window?.addEventListener(
        'touchmove',
        () => {
          postMessageToWebview({ message: 'iframeClicked' });
        },
        false
      );
      window?.addEventListener(
        'scrollend',
        () => {
          postMessageToWebview({ message: 'iframeClicked' });
        },
        false
      );
    },
    setKioskConfigData({ commit, dispatch }, config) {
      const {
        printerIp,
        printerPort,
        posServerIp,
        usingExternalKioskPrinter,
      } = config;

      commit('setIsKiosk', true);
      commit('setPrinterDetails', {
        ipAddress: printerIp,
        port: printerPort,
      });
      commit('setTerminalWebSocketUrl', posServerIp);

      if (usingExternalKioskPrinter) {
        commit('setUsingExternalKioskPrinter', usingExternalKioskPrinter);
        dispatch('subscribeToExternalKioskPrinterEvents');
      }
    },
    askForKioskConfig({ getters }) {
      const restaurantId = getters.getCurrentRestaurantId;
      const mainLanguage = getters.mainLanguage;

      postMessageToWebview({
        message: 'getKioskConfigData',
        restaurantId,
        mainLanguage,
      });
    },
    printReceiptWithKioskPrinter(_, receipt) {
      postMessageToWebview({
        message: 'printReceipt',
        receiptData: receipt,
      });
    },
    changeKioskLanguage(_, language) {
      postMessageToWebview({
        message: 'changeLanguage',
        language,
      });
    },
  },
  getters: {
    externalGPayWalletAvailable(state) {
      return state.externalWallets.includes('googlePay');
    },
  },
};

export const postMessageToWebview = message => {
  // Electron webview
  if (!window?.ReactNativeWebView && window?.parent) {
    window.parent.postMessage(JSON.stringify(message), '*');
    return;
  }

  // RN webview
  window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
};
