var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"menu-controls"},[(_vm.showPayGo)?_c('div',{staticClass:"flex"},[_c('Button',{attrs:{"block":"","data-cy":"pay-go-button-cta","dark":!_vm.isPlaceOrderButtonWhite,"white":_vm.isPlaceOrderButtonWhite,"background-color":this.$store.getters.customizationMenuPlaceOrderButtonBackgroundColor},on:{"click":_vm.navigateToPayGo}},[_c('span',{style:({
          color: this.$store.getters
            .customizationMenuPlaceOrderButtonTextColor,
        })},[_vm._v(_vm._s(_vm.$t('screens.menu.payBillCta')))])])],1):_vm._e(),(_vm.$store.getters.functionalityOrderEnabled)?_c('div',{staticClass:"flex d-flex justify-between",staticStyle:{"gap":"1em"}},[(_vm.isViewOrderButtonActive)?_c('Button',{staticStyle:{"width":"33%"},on:{"click":_vm.navigateToOrders}},[_c('div',{staticClass:"place-order-button-content tp-text-subtitle-semibold-white"},[_vm._v(" "+_vm._s(_vm.getGoToOrdersButtonText)+" "),_c('img',{attrs:{"src":require(`@/assets/images/icons/food-icon.svg`)}})])]):_vm._e(),(_vm.isPlaceOrderButtonActive)?_c('Button',{attrs:{"dark":!_vm.isPlaceOrderButtonWhite,"white":_vm.isPlaceOrderButtonWhite,"background-color":this.$store.getters.customizationMenuPlaceOrderButtonBackgroundColor,"text-color":this.$store.getters.customizationMenuPlaceOrderButtonTextColor,"data-cy":"place-order-button"},on:{"click":_vm.navigateToPlaceOrder}},[_c('div',{staticClass:"place-order-button-content",class:{
          'place-order-button-content--dark-text': _vm.isPlaceOrderButtonWhite,
        }},[_c('div',{staticClass:"place-order-button-content__label tp-text-subtitle-semibold-white",style:({
            color:
              this.$store.getters.customizationMenuPlaceOrderButtonTextColor +
              '!important',
          })},[_vm._v(" "+_vm._s(_vm.$t('screens.menu.viewOrderCtaLabel'))+" ")]),_c('div',{staticClass:"place-order-button-content__cart-count"},[_vm._v(" "+_vm._s(_vm.$store.getters.cartCount)+" ")])])]):(
        _vm.$store.getters.ordersTemporarilyDisabled &&
          _vm.$store.getters.functionalityOrderEnabled &&
          !_vm.$store.getters.functionalityPayGoEnabled
      )?_c('Button',{attrs:{"dark":"","disabled":"","center":""}},[_vm._v(" "+_vm._s(_vm.getOrdersTemporaryDisabledButtonText)+" ")]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }