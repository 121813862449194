<template>
  <div>
    <div v-if="loading" class="splash-loader-screen">
      <lottie-vue-player
        src="/assets/animations/greet-logo.json"
        style="width: 120px"
        autoplay
        loop
      />
    </div>
    <div
      v-if="menuLoaded"
      class="menu"
      :class="{
        'menu--controls-enabled':
          $store.getters.functionalityOrderEnabled ||
          $store.getters.functionalityPayGoEnabled,
      }"
      data-app
      data-cy="menu-screen"
    >
      <MenuHeader :show-back="showBack" :on-back="goBack" />
      <div id="categories">
        <LayoutCard
          v-if="$store.getters.quickAccessMenu.length > 0"
          id="quick-access-menu-block"
          :hide-background="!!$store.getters.customizationMenuHideLayoutCards"
          :use-custom-background-color="true"
        >
          <div
            v-if="!$store.getters.customizationHideQuickAccessMenuTitle"
            class="quick-access-menu-title tp-text-title-bold mb-5"
            :class="{
              'quick-access-menu-title--center':
                $store.getters.customizationMenuCategoriesCenter,
              'quick-access-menu-title--with-background':
                $store.getters.customizationMenuCategoriesBackgroundColor,
            }"
          >
            <span v-if="$store.getters.customizationQuickAccessMenuTitleText">
              {{ $t($store.getters.customizationQuickAccessMenuTitleText) }}
            </span>
            <span v-else>{{ $t('screens.menu.quickAccessTitle') }}</span>
          </div>
          <QuickAccessMenu></QuickAccessMenu>
        </LayoutCard>
        <special-deals-category></special-deals-category>
        <div v-if="fetchingMenu" class="text-center">
          <Spinner
            class="mt-10"
            :color="customSpinnerColor"
            :stroke-width="2"
            :width="40"
            :height="40"
          />
        </div>
        <div v-else>
          <LayoutCard
            v-for="(category, key) in $store.getters.categoriesWithBanners"
            :id="`category-${category.hash}`"
            :key="key"
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0],
                rootMargin: '-30% 0px -69% 0px',
              },
            }"
            :hide-background="
              !!$store.getters.customizationMenuHideLayoutCards ||
                (!!$store.getters.customizationMenuHideLayoutCardsForBanners &&
                  category.type === 'banner')
            "
            :blur="!isDrinkingAge && category.age_restricted"
            :hash="category.hash"
            :is-banner="category.type === 'banner'"
            :use-custom-background-color="true"
            :compact="category.type === 'banner'"
          >
            <category :category="category" :index="key"></category>
            <template
              v-if="!isDrinkingAge && category.age_restricted"
              v-slot:actions
            >
              <DrinkingAgeOverlay :visible="!isDrinkingAge" />
            </template>
          </LayoutCard>
        </div>
        <MenuControls />
      </div>
      <more-information></more-information>
      <Transition name="slide-from-bottom">
        <MenuItemSheet
          v-if="isMenuItemSheetOpen"
          v-bind="openedMenuItem"
        ></MenuItemSheet>
      </Transition>
      <Transition name="opacity">
        <div v-if="isMenuItemSheetOpen" class="menu-item-sheet-overlay"></div>
      </Transition>

      <Transition name="slide-from-bottom">
        <OrderInfo
          v-if="ordersEnabled && selectedOrder"
          v-bind="selectedOrder"
        ></OrderInfo>
      </Transition>
      <Transition name="opacity">
        <div v-if="ordersEnabled && selectedOrder" class="backdrop"></div>
      </Transition>
      <Transition name="opacity">
        <ScrollUpButton
          v-if="showScrollUp"
          :controls-enabled="controlsEnabled"
        ></ScrollUpButton>
      </Transition>
    </div>
    <SplashScreen
      v-else-if="loadingFailure"
      :status="status"
      :failure="loadingFailure"
      :loading-screen-customizations="loadingScreenCustomizations"
    ></SplashScreen>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import MenuHeader from '@/components/Menu/MenuHeader.vue';
import Spinner from '@/components/Spinner.vue';

import Category from '@/components/Category.vue';
import SpecialDealsCategory from '@/components/SpecialDealsCategory.vue';
import MoreInformation from '@/components/MoreInformation';
import LayoutCard from '../components/LayoutCard.vue';
import MenuItemSheet from '../components/MenuItem/MenuItemSheet.vue';
import OrderInfo from '@/components/Orders/OrdersView/OrderInfo.vue';
import SplashScreen from '@/components/SplashScreen.vue';
import ScrollUpButton from '@/components/ScrollUpButton.vue';
import QuickAccessMenu from '@/components/QuickAccessMenu.vue';
import MenuControls from '@/components/MenuControls.vue';
import DrinkingAgeOverlay from '@/components/Menu/DrinkingAgeOverlay.vue';

export default {
  name: 'Menu',
  components: {
    MenuHeader,
    // CollapsingTopBar,
    Category,
    SpecialDealsCategory,
    MoreInformation,
    LayoutCard,
    MenuItemSheet,
    OrderInfo,
    SplashScreen,
    ScrollUpButton,
    QuickAccessMenu,
    MenuControls,
    DrinkingAgeOverlay,
    Spinner,
  },
  data() {
    return {
      pageTitle: 'Loading menu - Greet.menu',
      menuLoaded: false,
      loading: false,
      loadingFailure: false,
      status: 'active',
      show: false,
      bodyScrollPosition: window.pageYOffset,
      loadingScreenCustomizations: false,
      showScrollUp: false,
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  computed: {
    isMenuItemSheetOpen() {
      return this.$store.state.openedMenuItem !== false;
    },
    openedMenuItem() {
      return this.$store.state.items.find(item => {
        return item.id === this.$store.state.openedMenuItem;
      });
    },
    selectedOrder() {
      const { getOrderByUniqueOrderNumber } = this.$store.getters;
      return getOrderByUniqueOrderNumber(this.$store.state.openedOrder);
    },
    ordersEnabled() {
      return (
        this.$store.getters.functionalityOrderEnabled ||
        this.$store.getters.functionalityPayGoEnabled
      );
    },
    controlsEnabled() {
      if (!this.ordersEnabled) {
        return false;
      }

      return (
        this.$store.getters.cartCount >= 0 ||
        this.$store.getters.ordersCount > 0
      );
    },
    isDrinkingAge() {
      return this.$store.getters.drinkingAgeApproved;
    },
    foodCourtSession() {
      return this.$store.getters.getFoodCourtSession;
    },
    restaurantFoodCourtHash() {
      return this.$store.getters.restaurantFoodCourtHash;
    },
    showBack() {
      return (
        this.$store.getters.hasMenuFilters ||
        (!!this.restaurantFoodCourtHash &&
          !this.$store.getters.tableTypeIsSelfService) ||
        this.$store.getters.functionalityShowSelectScreenEnabled ||
        this.$store.getters.functionalityPayGoEnabled
      );
    },
    fetchingMenu() {
      return this.$store.getters.fetchingMenu;
    },
    customSpinnerColor() {
      const loaderCustomizations = this.$store.getters
        .loadingScreenCustomizations;

      return loaderCustomizations?.loader_color || '#000';
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    this.$store.dispatch('fetchMenuData', { session: sessionToken });
    try {
      if (this.$store.state.restaurant.id === null) {
        this.loading = true;
        Promise.all([
          await this.$store.dispatch('fetchSessionData', {
            session: sessionToken,
            force: this.foodCourtSession,
          }),
          await new Promise(resolve =>
            setTimeout(() => {
              resolve();
            }, 1200)
          ),
        ]);
        this.loading = false;
      }

      this.pageTitle = this.$store.state.restaurant.name + ' - Greet.menu';
      this.menuLoaded = true;
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'menu_loaded',
      });

      await this.$store.dispatch('fetchCart', sessionToken);
      await this.$store.dispatch('fetchOrders');

      let scrolledToBottom = false;
      let firstScrollHappened = false;
      window.onscroll = () => {
        if (window.pageYOffset >= 900) {
          this.showScrollUp = true;
        } else {
          this.showScrollUp = false;
        }

        if (window.scrollY > 15 && !firstScrollHappened) {
          firstScrollHappened = true;
          this.$store.dispatch('sendAnalyticsEvent', {
            event: 'first_scroll',
          });
        }

        // Track scrolled to bottom
        if (
          window.innerHeight + window.scrollY >= document.body.offsetHeight &&
          !scrolledToBottom
        ) {
          scrolledToBottom = true;
          this.$store.dispatch('sendAnalyticsEvent', {
            event: 'scrolled_to_bottom',
          });
        }
      };
    } catch (error) {
      this.loadingFailure = true;
      if (error.response?.data?.status) {
        this.status = error.response?.data?.status;
      } else {
        throw error;
      }
    } finally {
      this.loading = false;
    }

    // Montonio order token validation
    if (this.$route.query['order-token']) {
      this.$store.dispatch('openOrder', this.$route.query?.order);

      this.axios.post(
        `/payments/montonio/callback/${this.$store.state.session}`,
        {
          orderToken: this.$route.query['order-token'],
        }
      );
    }

    if (this.ordersEnabled) {
      this.$store.dispatch('subscribeToPusherChannels');
      if (this.$route.query?.order) {
        this.$store.dispatch('openOrder', this.$route.query?.order);
        this.$router.push(this.$route.path);
      }
    }
  },
  methods: {
    onIntersect(entries, _, isIntersecting) {
      const entry = entries[0];
      const intersectionRatio = entry.intersectionRatio;
      const categoryHash = entry.target.id;
      const categorySliderChanging = this.$store.state.categorySliderChanging;

      if (isIntersecting && intersectionRatio > 0 && !categorySliderChanging) {
        this.changeActiveCategoryTab(categoryHash);
      }
    },
    changeActiveCategoryTab: debounce(async function(categoryHash) {
      this.$store.dispatch('changeActiveCategoryTab', categoryHash);
    }, 300),
    toggleMoreInformation: function() {
      this.$store.dispatch('toggleMoreInformation');
    },
    refreshPage() {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'onfail-reload-button-click',
      });
      this.$router.go(this.$router.currentRoute);
    },
    goBack() {
      if (this.restaurantFoodCourtHash) {
        return this.$router.push({
          name: 'FoodCourt',
          params: { foodCourtHash: this.restaurantFoodCourtHash },
          replace: true,
        });
      } else if (this.$store.getters.hasMenuFilters) {
        return this.$router.push({
          name: 'MenuFilters',
          params: { session: this.$store.state.session },
          replace: true,
        });
      } else {
        return this.$router.push({
          name: 'Select',
          params: { session: this.$store.state.session },
          replace: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.menu {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  background: var(--menu-background-image), var(--menu-background-color);
  background-position: top;
  background-size: contain;
  background-repeat: revert;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 3em;
  &--controls-enabled {
    padding-bottom: 88px;
  }

  &__top-bar {
    background-color: white;
  }

  max-width: $max-app-width;
}

.no-uppercase {
  text-transform: none !important;
}

.menu-updating {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.menu-item-sheet-overlay,
.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 210;
  background: rgba(0, 0, 0, 0.5);
  overscroll-behavior-y: none;
}

.float {
  position: fixed;
  height: 60px;
  width: 100%;
  bottom: 40px;
  padding: 40px;
}

.row {
  display: flex;
  flex-direction: row;
}

.place-order-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;

  &__label {
    color: white !important;
  }

  &__cart-count {
    border-radius: 100%;
    background: white;
    color: black;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--dark-text {
    .place-order-button-content__label {
      color: black !important;
    }

    .place-order-button-content__cart-count {
      background: none;
      border: 1px solid black;
    }
  }
}

.quick-access-menu-title {
  font-family: var(--menu-categories-font-family) !important;
  font-weight: var(--menu-categories-font-weight) !important;
  letter-spacing: var(--menu-categories-letter-spacing) !important;
  &--center {
    text-align: center;
  }
  &--with-background {
    background: var(--menu-categories-background-color) !important;
    margin-left: -16px;
    margin-right: -16px;
    padding: 7px 0;
  }
  color: var(--menu-categories-font-color);
}
</style>
