<template>
  <v-avatar :size="size" color="white" @click="onClick">
    <v-img :src="imageUrl" :alt="name" :lazy-src="lazySrc" class="avatar-image">
      <template #placeholder>
        <v-skeleton-loader type="image" :height="size" />
      </template>
    </v-img>
  </v-avatar>
</template>

<script>
import { defaultPhotoUrl } from '../../store/modules/user/api';

export default {
  props: {
    size: {
      type: Number,
      default: 120,
    },
    photoUrl: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    lazySrc: {
      type: String,
      default: null,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    imageUrl() {
      return this.photoUrl || defaultPhotoUrl(this.name);
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-image {
  border-radius: 50%;
  border: 3px solid white;
}
</style>
