<template>
  <div v-if="hasCategories" class="category-slider">
    <div class="blue-gradient"></div>
    <div class="categories-wrapper align-center d-flex">
      <div id="category-slider" class="category-slider__wrapper">
        <div
          v-for="(category, key) in categories"
          :id="`slider-category-${category.hash}`"
          :key="key"
          class="category-item no-uppercase text-subtitle-2"
          :class="{ active: activeTab === `category-${category.hash}` }"
          @click="tabClick(category, 'slider')"
        >
          {{ $t(category.name) }}
        </div>
      </div>
      <div class="categories-dropdown-btn" @click="toggleCategoryDropdown">
        <img
          :src="
            $store.getters.customizationMenuDarkMode
              ? require(`@/assets/images/icons/more-info-icon-light.svg`)
              : require(`@/assets/images/icons/more-info-icon.svg`)
          "
        />
      </div>
    </div>
    <div
      v-if="dropdownOpen"
      id="categories-dropdown-list"
      v-click-outside="closeCategoryDropdown"
      class="categories-dropdown-list"
    >
      <li
        v-for="(category, key) in categories"
        :id="`category-dropdown-category-${category.hash}`"
        :key="`categories-dropdown-item-${key}`"
        class="categories-dropdown-list__item tp-text-dropdown-list"
        :class="{
          'categories-dropdown-list__item--active':
            activeTab === `category-${category.hash}`,
        }"
        @click="tabClick(category, 'dropdown')"
      >
        {{ $t(category.name) }}
      </li>
    </div>
  </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';
export default {
  data() {
    return {
      changingSliderTimout: null,
      activeTab: 0,
      dropdownOpen: false,
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categorySliderCategories;
    },
    hasCategories() {
      return this.categories.length > 0;
    },
  },
  watch: {
    '$store.state.activeCategoryTab': function(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (this.activeTab === newValue) return;

      const tabElement = document.getElementById('slider-' + newValue);
      const sliderElement = document.getElementById('category-slider');

      if (tabElement === null) return;

      this.activeTab = newValue;

      sliderElement.scrollTo({
        left: tabElement.offsetLeft - 120,
        behavior: 'smooth',
      });
      const droptDownMenuElement = document.getElementById(
        'categories-dropdown-list'
      );
      const dropdownElement = document.getElementById(
        'category-dropdown-' + newValue
      );

      if (droptDownMenuElement !== null) {
        droptDownMenuElement.scrollTo({
          top: dropdownElement.offsetTop - 120,
          behavior: 'smooth',
        });
      }
    },
    '$store.state.categoryDropdownOpen': function() {
      this.dropdownOpen = this.$store.state.categoryDropdownOpen;
    },
  },
  mounted() {
    smoothscroll.polyfill();

    let categorySliderScrolled = false;
    this.$el
      .querySelector('#category-slider')
      ?.addEventListener('scroll', e => {
        if (e.target.scrollLeft > 40 && !categorySliderScrolled) {
          categorySliderScrolled = true;
          this.$store.dispatch('sendAnalyticsEvent', {
            event: 'category_slider_scrolled',
          });
        }
      });
  },
  methods: {
    openSearch() {
      this.$store.dispatch('openSearch');
    },
    tabClick(category, origin = 'slider') {
      this.$store.dispatch(
        'changeActiveCategoryTab',
        `category-${category.hash}`
      );
      this.$store.dispatch('categorySliderChanging', true);
      this.$store.dispatch('closeCategoryDropdown');

      const scrollAnchor = '#category-' + category.hash;
      const offset =
        130 +
        parseInt(
          this.$store.getters.customizationMenuGoToCategoryAdditionalOffset || 0
        );
      this.$vuetify.goTo(scrollAnchor, {
        duration: 300,
        offset: offset,
        easing: 'easeInOutCubic',
      });

      if (this.changingSliderTimout) clearTimeout(this.changingSliderTimout);
      this.changingSliderTimout = setTimeout(() => {
        this.$store.dispatch('categorySliderChanging', false);
      }, 500);

      if (origin === 'slider') {
        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'category_slider_selected',
          category_name: category.name,
        });
      } else if (origin === 'dropdown') {
        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'category_dropdown_selected',
          category_name: category.name,
        });
      }
    },
    toggleCategoryDropdown() {
      if (this.$store.state.categoryDropdownOpen) {
        this.$store.dispatch('closeCategoryDropdown');
      } else {
        this.$store.dispatch('openCategoryDropdown');

        let scrollInDropdownInterval = setInterval(() => {
          const dropdownElement = document.getElementById(
            'category-dropdown-' + this.activeTab
          );
          const droptDownMenuElement = document.getElementById(
            'categories-dropdown-list'
          );
          if (dropdownElement !== null) {
            droptDownMenuElement.scrollTo({
              top: dropdownElement.offsetTop - 120,
              behavior: 'smooth',
            });
            clearInterval(scrollInDropdownInterval);
          }
        }, 100);

        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'category_dropdown_opened',
        });
      }
    },
    closeCategoryDropdown() {
      this.$store.dispatch('closeCategoryDropdown');
    },
  },
};
</script>

<style lang="scss">
.categories-wrapper {
  position: relative;
}

.category-slider__wrapper {
  display: flex;
  overflow-x: scroll;
  min-height: 48px;
  scroll-behavior: smooth;
  width: 88vw;
}

.category-slider__wrapper::-webkit-scrollbar {
  display: none;
}

.category-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  line-height: normal;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--menu-category-slider-inactive-color);
  white-space: nowrap;
  font-weight: var(--menu-categories-slider-font-weight) !important;

  &.text-subtitle-2 {
    font-family: var(--menu-categories-font-family) !important;
  }

  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &.active {
    color: var(--color-primary);
    border-bottom: solid 3px var(--color-primary);
    padding-top: 3px;
  }
}

.categories-dropdown-btn {
  height: inherit;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
}

.categories-dropdown-list {
  position: fixed;
  right: 0;
  background: $color-white;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px rgb(0 0 0 / 14%);
  border-radius: 0px 0px 0px 10px;
  max-width: 185px;
  max-height: 70vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &__item {
    list-style: none;
    padding: 12px 24px;
    &:first-child {
      padding: 16px 24px 12px 24px;
    }
    &:last-child {
      padding: 12px 24px 16px 24px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    }

    &--active,
    &:active,
    &:focus {
      background: rgba(0, 0, 0, 0.04);
      color: var(--color-primary);
    }
  }
}
</style>
