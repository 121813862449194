<template>
  <div
    class="option d-flex flex-row justify-space-between"
    :class="{ 'option--out-of-stock': !in_stock }"
  >
    <v-row no-gutters>
      <v-col cols="8">
        <div class="option-info__name tp-text-body-2">
          {{ $t(name) }}
        </div>
        <div
          class="option-info__markup tp-text-caption"
          v-if="!displayFullPrice"
        >
          <span>+{{ getFormattedMarkup }}</span>
          <span
            v-if="getFormattedLoyaltyMarkup"
            class="option-info-markup__loyalty"
          >
            +{{ getFormattedLoyaltyMarkup }}
            <img
              v-if="$store.getters.customizationLoyaltyCardIcon"
              class="ml-1"
              style="width: 16px;"
              :src="$store.getters.customizationLoyaltyCardIcon"
            />
            <v-icon
              v-else
              small
              :color="$store.getters.customizationPrimaryColor || 'primary'"
              class="ml-1"
            >
              mdi-credit-card-fast-outline
            </v-icon>
          </span>
        </div>
      </v-col>
      <v-col cols="4" class="option-right-column">
        <div class="option-info__full-price-column" v-if="displayFullPrice">
          <price :price="getFullPrice"></price>
          <loyalty-price
            v-if="getFullLoyaltyPrice"
            :price="getFullLoyaltyPrice"
            :smaller-text="true"
          ></loyalty-price>
        </div>
        <div v-if="isOrderingEnabled">
          <v-radio
            v-if="singleSelection"
            :key="id"
            :label="``"
            :value="id"
            :ripple="false"
            :disabled="!in_stock"
          />

          <v-checkbox
            v-else
            ref="optionCheckbox"
            v-model="value.selected"
            :readonly="(maxSelectionsReached && !value.selected) || !in_stock"
            :ripple="false"
            true-value
            :class="{
              disabled: (maxSelectionsReached && !value.selected) || !in_stock,
            }"
            @click="onCheckBoxClick"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formatPriceWithCurrency from '../../../helpers/formatPriceWithCurrency.js';
import Price from '@/components/MenuItem/Price.vue';
import LoyaltyPrice from '@/components/MenuItem/LoyaltyPrice.vue';
export default {
  props: {
    name: String,
    markup: Number,
    loyalty_markup: Number,
    id: {
      type: Number,
      required: true,
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    in_stock: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'single',
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    value: Object,
    maxSelectionsReached: {
      type: Boolean,
      default: false,
    },
    displayFullPrice: Boolean,
    menuItemPrice: Number,
    menuItemLoyaltyPrice: [Number, Boolean],
    isCategoryItemsAvailable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Price,
    LoyaltyPrice,
  },
  data() {
    return {
      orderingEnabled: false,
    };
  },
  computed: {
    optionDisabled() {
      return !this.in_stock;
    },
    getFormattedMarkup() {
      return formatPriceWithCurrency(
        this.displayFullPrice ? this.markup + this.menuItemPrice : this.markup,
        this.$store.state.restaurant.currency.code,
        this.$store.state.restaurant.locale.locale_code
      );
    },
    getFullPrice() {
      if (this.displayFullPrice) {
        return this.menuItemPrice + this.markup;
      }
      return false;
    },
    getFormattedLoyaltyMarkup() {
      if (
        !this.$store.state.restaurant.loyaltyPricingEnabled ||
        this.loyalty_markup === null
      ) {
        return false;
      }

      return formatPriceWithCurrency(
        this.displayFullPrice
          ? this.loyalty_markup +
              (this.menuItemLoyaltyPrice !== false
                ? this.menuItemLoyaltyPrice
                : this.menuItemPrice)
          : this.loyalty_markup,
        this.$store.state.restaurant.currency.code,
        this.$store.state.restaurant.locale.locale_code
      );
    },
    getFullLoyaltyPrice() {
      if (
        this.displayFullPrice &&
        this.$store.state.restaurant.loyaltyPricingEnabled &&
        this.loyalty_markup !== null
      ) {
        return (
          this.loyalty_markup +
          (this.menuItemLoyaltyPrice !== false
            ? this.menuItemLoyaltyPrice
            : this.menuItemPrice)
        );
      }
      return false;
    },
    isOrderingEnabled() {
      return (
        this.$store.getters.functionalityOrderEnabled &&
        !this.$store.getters.ordersTemporarilyDisabled &&
        this.isCategoryItemsAvailable
      );
    },
  },
  methods: {
    onCheckBoxClick() {
      const isReadonly = this.$refs?.optionCheckbox?.readonly;
      if (this.maxSelectionsReached && isReadonly) {
        this.$emit('maxSelectionsReached');
      }
    },
  },
};
</script>

<style lang="scss">
.option-right-column {
  display: flex;
  justify-content: flex-end;

  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-messages {
    display: none;
  }
}

.option {
  &--out-of-stock {
    opacity: 0.5;
  }
}

.option:not(:last-child) {
  padding-bottom: 24px;
}

.option-info {
  &__name {
    padding-bottom: 2px;
  }
  &__full-price-column {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
}

.option-info-markup {
  &__loyalty {
    color: var(--color-primary);
    display: flex;
    align-items: center;
    margin-top: 1px;
  }
  &--full-price {
    display: block;
  }

  &--markup-price {
    &::before {
      content: '+';
    }
  }
}
.disabled {
  opacity: 0.6;
}
</style>
