var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-section",class:{
    'category-section-banner': _vm.isCategoryTypeBanner && _vm.category.banner,
  },attrs:{"id":`category-${_vm.category.hash}`}},[(_vm.isCategoryTypeBanner && _vm.category.banner && _vm.categoryBannerImageSrc)?_c('div',{staticClass:"category__banner",class:{
      'category__banner--full-width':
        _vm.$store.getters.customizationMenuBannerFullWidth,
    }},[_c('img',{staticClass:"category-banner-image",attrs:{"src":_vm.categoryBannerImageSrc,"width":"100%"}})]):(!_vm.$store.getters.customizationMenuHideCategoryTitle)?_c('div',{staticClass:"mb-6"},[(_vm.$store.getters.customizationMenuCategoryDoubleDividerEnabled)?_c('div',{staticClass:"category__divider mb-3"}):_vm._e(),_c('div',{staticClass:"category__title tp-text-title-bold",class:{
        'category__title--center':
          _vm.$store.getters.customizationMenuCategoriesCenter,
        'category__title--with-background':
          _vm.$store.getters.customizationMenuCategoriesBackgroundColor,
      },style:({ color: _vm.rotatingFontColor })},[_c('div',[_vm._v(_vm._s(_vm.$t(_vm.category.name)))])]),(_vm.category.description)?_c('div',{staticClass:"category__description tp-text-body-gray mt-3",class:{
        'category__description--center':
          _vm.$store.getters.customizationMenuCategoriesCenter,
      },domProps:{"innerHTML":_vm._s(_vm.$t(_vm.category.description))}}):_vm._e(),(
        _vm.$store.getters.customizationMenuCategoryDividerEnabled ||
          _vm.$store.getters.customizationMenuCategoryDoubleDividerEnabled
      )?_c('div',{staticClass:"category__divider mt-3",class:{
        'category__divider--dashed':
          _vm.$store.getters.customizationMenuCategoryDividerStyle === 'dashed',
        'category__divider--dotted':
          _vm.$store.getters.customizationMenuCategoryDividerStyle === 'dotted',
      }}):_vm._e()]):(_vm.category.description)?_c('div',{staticClass:"category__description tp-text-body-gray mb-3",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.category.description))}}):_vm._e(),(_vm.itemsWithoutSubcategory.length > 0)?_c('div',_vm._l((_vm.itemsWithoutSubcategory),function(item,index){return _c('menu-item',_vm._b({key:index,attrs:{"data-cy":`category-${_vm.category.id}-item-${index}`,"full-width-info":_vm.showFullWidthInfo,"display-as-out-of-stock":!_vm.isCategoryItemsAvailable &&
          _vm.category.availability.type === 'availability',"rotating-category-color":_vm.rotatingFontColor}},'menu-item',item,false))}),1):_vm._e(),_vm._l((_vm.subcategories),function(subcategory,key){return _c('subcategory',_vm._b({key:'category' + key,class:{ 'mt-4': key !== 0 }},'subcategory',subcategory,false))})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }