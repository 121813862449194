<template>
  <button
    class="scroll-up"
    :class="{ 'scroll-up--controls-enabled': controlsEnabled }"
    @click="scrollUp"
  >
    <img :src="require(`@/assets/images/icons/scroll-up-icon.svg`)" />
  </button>
</template>

<script>
export default {
  props: {
    controlsEnabled: Boolean,
  },
  methods: {
    scrollUp() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-up {
  position: fixed;
  bottom: 15px;
  border-radius: 40px;
  background: #f5f5f5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  right: 15px;
  z-index: 6;

  &--controls-enabled {
    bottom: 100px;
  }
}
</style>
