<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
    <Transition name="bounce">
      <Toast
        v-if="$store.state.toastOpened"
        :title="$store.state.toastData.title"
        :description="$store.state.toastData.description"
        :action="$store.state.toastData.action"
        :is-error="$store.state.toastData.isError"
        :do-not-close="$store.state.toastData.doNotClose"
      ></Toast>
    </Transition>
    <OrdersOnboardingModal />
    <FoodCourtOnboardingModal />
    <PayGoOnboardingModal />
    <PopupModal />
    <KioskTerminalModal />
  </v-app>
</template>

<script>
import Toast from '@/components/Toast.vue';
import OrdersOnboardingModal from '@/components/Onboarding/OrdersOnboardingModal.vue';
import FoodCourtOnboardingModal from '@/components/Onboarding/FoodCourtOnboardingModal.vue';
import PayGoOnboardingModal from '@/components/Onboarding/PayGoOnboardingModal.vue';
import PopupModal from '@/components/Marketing/PopupModal.vue';
import { defaultTheme } from './utils/themeDefaults';
import KioskTerminalModal from '@/components/Payments/KioskTerminalModal.vue';

export default {
  name: 'App',
  metaInfo: {
    meta: [{ name: 'robots', content: 'noindex,nofollow' }],
  },
  components: {
    Toast,
    OrdersOnboardingModal,
    FoodCourtOnboardingModal,
    PopupModal,
    PayGoOnboardingModal,
    KioskTerminalModal,
  },
  data() {
    return {
      isUserOnline: true,
      printerInterval: null,
      worldlineInterval: null,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    foodCourtHash() {
      // If restaurant is in food court and there is no hash in cookie yet -
      // we use restaurant hash that is returned with restaurant details
      return (
        this.$route.params.foodCourtHash ||
        this.$store.getters.getFoodCourtHash ||
        this.$store.getters.restaurantFoodCourtHash
      );
    },
    foodCourt() {
      return this.$store.getters.getFoodCourtDetails;
    },
    isKiosk() {
      return this.$store.getters.isKiosk;
    },
    tableTypeIsSelfService() {
      return this.$store.getters.tableTypeIsSelfService;
    },
  },
  watch: {
    isUserOnline: function() {
      if (!this.isUserOnline) {
        this.$store.dispatch('setToastData', {
          title: this.$i18n.translate('components.toast.connectionOffTitle'),
          description: this.$i18n.translate(
            'components.toast.connectionOffDescription'
          ),
          action: () => {
            location.reload();
          },
          isError: true,
          doNotClose: true,
        });
        this.$store.dispatch('openToast');
      } else {
        this.$store.dispatch('closeToast');
        // If connection was lost and orders updated, we should refetch orders
        this.$store.dispatch('fetchOrders');
      }
    },
    foodCourt(newVal, oldVal) {
      if (newVal && oldVal.id !== newVal.id) {
        this.$store.dispatch('subscribeToFoodCourtChannels');
      }
    },
    foodCourtHash(newVal, oldVal) {
      if (newVal && oldVal !== newVal) {
        this.$store.dispatch('fetchFoodCourtDetails', {
          hash: newVal,
        });
      }
    },
    currentRouteName(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === 'Order') {
          setTimeout(() => {
            this.$store.dispatch('checkPayGoOnboardingDone');
          }, 2000);
        }
      }
    },
    tableTypeIsSelfService(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.$store.dispatch('subscribeToKioskMessages');
        this.$store.dispatch('askForKioskConfig');
        this.$store.dispatch('subscribeToTouchEvents');
      }
    },
  },
  beforeDestroy() {
    if (this.isKiosk) {
      this.$store.dispatch('destroyPosTerminal');
    }
    if (this.printerInterval) {
      clearInterval(this.printerInterval);
    }
    if (this.worldlineInterval) {
      clearInterval(this.worldlineInterval);
    }
  },
  created() {
    if (this.$gtm) {
      this.$gtm.enable(true);
    }
  },
  mounted() {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    this.setDefaultTheme();

    if (typeof navigator !== 'undefined') {
      setInterval(() => {
        this.isUserOnline = navigator.onLine;
      }, 5000);
    }

    if (this.foodCourtHash) {
      this.$store.dispatch('fetchFoodCourtDetails', {
        hash: this.foodCourtHash,
      });
    }

    document.addEventListener('visibilitychange', () => {
      const hidden = document.hidden;
      if (!hidden) {
        this.$store.dispatch('fetchOrders');
      }
    });

    // Onboarding modals, also watchers for onboarding done
    this.$nextTick(() => {
      this.$store.dispatch('checkDrinkingAgeApproved');
      if (this.currentRouteName === 'Menu') {
        setTimeout(() => {
          this.$store.dispatch('checkOrderOnboardingDone');
        }, 2000);
      } else if (this.currentRouteName === 'FoodCourt') {
        setTimeout(() => {
          this.$store.dispatch('checkFoodCourtOnboardingDone');
        }, 2000);
      } else if (this.currentRouteName === 'Order') {
        setTimeout(() => {
          this.$store.dispatch('checkPayGoOnboardingDone');
        }, 2000);
      }
    });

    // When user clicks back button, we need to close toasts and bottom sheets.
    window.addEventListener('popstate', () => {
      this.$store.dispatch('closeMenuItem');
      this.$store.dispatch('closeOrder');
      this.$store.dispatch('closeToast');
    });

    if (this.isKiosk) {
      this.$store.dispatch('subscribeToFetchMenu');
      // Set up printer script
      let printerScript = document.createElement('script');
      printerScript.setAttribute('src', '/epos-2.27.0.js');
      printerScript.setAttribute('type', 'text/javascript');
      document.head.appendChild(printerScript);
      printerScript.onload = () => {
        this.tryToConnectToPrinter();
      };

      this.printerInterval = setInterval(() => {
        this.tryToConnectToPrinter();
      }, 10000);
      this.worldlineInterval = setInterval(() => {
        this.$store.dispatch('subscribeToPosTerminal');
      }, 10000);
    }
  },
  methods: {
    closeToast() {
      this.$store.dispatch('closeToast');
    },
    setDefaultTheme() {
      defaultTheme.forEach(theme => {
        document.documentElement.style.setProperty(theme.property, theme.value);
      });
    },
    tryToConnectToPrinter() {
      this.$store.dispatch('connectToPOSPrinter');
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/typography';
@import '@/scss/custom-fonts';

.lf-spinner {
  display: none;
}

.vue-lottie-player {
  background-color: transparent !important;
}

.v-application--wrap {
  min-height: unset !important;
  background-color: $color-background-gray !important;
}

@media only screen and (min-width: $max-app-width) {
  .container {
    max-width: $max-app-width !important;
  }
  .v-content__wrap {
    max-width: $max-app-width !important;
    margin: 0px auto;
  }
}

.main {
  background-color: $color-background-gray !important;
}

.v-btn {
  text-transform: none !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.theme--light.v-btn.v-btn--icon {
  color: #5f5f5f !important;
}

html,
body {
  background-color: $color-background-gray !important;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
  ::-webkit-scrollbar {
    display: none;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.slide-from-bottom-enter-active,
.slide-from-bottom-leave-active {
  transition: transform 0.2s ease;
}

.slide-from-bottom-enter,
.slide-from-bottom-leave-to {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  will-change: transform;
}

.opacity-enter-active,
.opacity-leave-active {
  transition: opacity 0.2s ease;
}

.opacity-enter,
.opacity-leave-to {
  opacity: 0;
}
</style>
