<template>
  <v-expand-transition>
    <v-banner v-show="visible" single-line color="#1f243c">
      <div
        class="d-flex tp-text-subtitle-semibold-white justify-center align-center font-weight-medium"
      >
        {{ $t('kovo11') }}
      </div>
    </v-banner>
  </v-expand-transition>
</template>

<script>
export default {
  name: 'SpecialOccasionBanner',
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    if (this.$moment().isSame('2025-03-11', 'day')) {
      setTimeout(() => {
        this.visible = true;
      }, 600);
    }
  },
};
</script>
