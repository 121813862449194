var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
    handler: _vm.trackMenuItemView,
    options: {
      threshold: [1.0],
    },
  }),expression:"{\n    handler: trackMenuItemView,\n    options: {\n      threshold: [1.0],\n    },\n  }"}],staticClass:"menu-item",class:{
    'menu-item--out-of-stock': !_vm.in_stock || _vm.displayAsOutOfStock,
    'menu-item--in-cart': _vm.menuItemIsInCart,
    'menu-item--skip-font-color-customization': _vm.skipFontColorCustomization,
    'menu-item--with-divider': _vm.enableMenuItemsDivider,
    'menu-item--with-divider--not-first':
      _vm.enableMenuItemsDivider && _vm.skipFirstItemDivider,
  },on:{"click":_vm.openSheet}},[_c('div',{staticClass:"menu-item__info",class:{
      'menu-item__info--full-width': _vm.fullWidthInfo && !_vm.photo,
      'pr-3': !_vm.fullWidthInfo,
    }},[_c('div',[_c('div',{staticClass:"menu-item-info__title-wrapper"},[(_vm.menuItemIsInCart)?_c('div',{staticClass:"menu-item-info__order-quantity-indicator tp-text-body-white"},[_vm._v(" "+_vm._s(_vm.cartQuantity)+" ")]):_vm._e(),_c('div',{staticClass:"menu-item-info__title tp-text-subtitle-semibold"},[_vm._v(" "+_vm._s(_vm.$t(_vm.name))+" ")])]),(
          _vm.description ||
            _vm.dishTagsDefaultAndCustomCombined.length > 0 ||
            !_vm.in_stock | _vm.displayAsOutOfStock
        )?_c('div',{staticClass:"menu-item-info__description tp-text-body-gray"},[(
            _vm.dishTagsDefaultAndCustomCombined.length > 0 &&
              _vm.in_stock &&
              !_vm.displayAsOutOfStock
          )?_c('dish-tags-list',{attrs:{"tags":_vm.dishTagsDefaultAndCustomCombined}}):_vm._e(),(_vm.in_stock && !_vm.displayAsOutOfStock)?_c('span',{style:(_vm.$store.getters
              .customizationMenuShortDescriptionRotatingCategoryColorsEnabled
              ? { color: _vm.rotatingCategoryColor }
              : {}),domProps:{"innerHTML":_vm._s(_vm.$t(_vm.descriptionComputed))}}):_c('span',[_vm._v(_vm._s(_vm.$t('components.menuItem.outOfStock')))])],1):_vm._e()]),_c('div',{staticClass:"menu-item-info__price-wrapper",class:{
        'menu-item-info__price-wrapper--align-end ml-5':
          _vm.fullWidthInfo && !_vm.photo,
        'menu-item-info__price-wrapper--align-self-center':
          _vm.$store.getters.customizationMenuItemsPriceVerticalAlignCenter,
      }},[_c('div',[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between",class:{ 'mt-2': !_vm.fullWidthInfo }},[_c('div',{staticClass:"d-flex flex-row align-center"},[(_vm.hasVariations)?_c('VariationPrice',{attrs:{"low-price":_vm.variationsLowPrice,"high-price":_vm.variationsHighPrice,"bigger":_vm.fullWidthInfo,"custom-font-family":_vm.$store.getters.customizationMenuItemsPriceFontFamily ||
                  _vm.$store.getters.customizationMenuFontOverrideFamily,"custom-font-weight":_vm.$store.getters.customizationMenuItemsPriceFontWeight,"custom-font-color":!(_vm.$store.getters.customizationMenuDarkMode && _vm.inSearchPage) &&
                  _vm.$store.getters.customizationMenuItemsPriceFontColor,"custom-font-size":_vm.$store.getters.customizationMenuItemsPriceFontSize,"custom-font-style":_vm.$store.getters.customizationMenuItemsPriceFontStyle}}):_c('price',{attrs:{"price":_vm.priceComputed,"old-price":_vm.oldPriceComputed,"bigger":_vm.fullWidthInfo,"show-from":_vm.price === 0,"custom-font-family":_vm.$store.getters.customizationMenuItemsPriceFontFamily ||
                  _vm.$store.getters.customizationMenuFontOverrideFamily,"custom-font-weight":_vm.$store.getters.customizationMenuItemsPriceFontWeight,"custom-font-color":!(_vm.$store.getters.customizationMenuDarkMode && _vm.inSearchPage) &&
                  _vm.$store.getters.customizationMenuItemsPriceFontColor,"custom-font-size":_vm.$store.getters.customizationMenuItemsPriceFontSize,"custom-font-style":_vm.$store.getters.customizationMenuItemsPriceFontStyle}}),(_vm.popular && !_vm.fullWidthInfo)?_c('popular-badge',{staticClass:"ml-2",attrs:{"bigger":false}}):_vm._e()],1)]),(_vm.loyaltyPricingEnabled)?_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[(_vm.hasVariations)?_c('VariationPrice',{attrs:{"low-price":_vm.variationsLoyaltyLowPrice,"high-price":_vm.variationsLoyaltyHighPrice,"loyalty-pricing":true,"bigger":_vm.fullWidthInfo,"custom-font-family":_vm.$store.getters.customizationMenuItemsPriceFontFamily ||
                _vm.$store.getters.customizationMenuFontOverrideFamily,"custom-font-weight":_vm.$store.getters.customizationMenuItemsPriceFontWeight,"custom-font-color":!(_vm.$store.getters.customizationMenuDarkMode && _vm.inSearchPage) &&
                _vm.$store.getters.customizationMenuItemsPriceFontColor,"custom-font-size":_vm.$store.getters.customizationMenuItemsPriceFontSize,"custom-font-style":_vm.$store.getters.customizationMenuItemsPriceFontStyle}}):_c('loyalty-price',{attrs:{"price":_vm.loyalty_price,"bigger":_vm.fullWidthInfo,"custom-font-family":_vm.$store.getters.customizationMenuItemsPriceFontFamily ||
                _vm.$store.getters.customizationMenuFontOverrideFamily,"custom-font-weight":_vm.$store.getters.customizationMenuItemsPriceFontWeight,"custom-font-size":_vm.$store.getters.customizationMenuItemsPriceFontSize,"custom-font-style":_vm.$store.getters.customizationMenuItemsPriceFontStyle}})],1):_vm._e()]),(_vm.popular && _vm.fullWidthInfo && !_vm.photo)?_c('popular-badge',{staticClass:"mt-2",attrs:{"bigger":false}}):_vm._e()],1)]),(_vm.thumbnail || _vm.photo)?_c('div',{staticClass:"menu-item__image"},[_c('LazyImage',{attrs:{"img":_vm.thumbnail || _vm.photo,"lazy-img":_vm.lqip}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }