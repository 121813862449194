<template>
  <v-card
    flat
    class="layout-card"
    :class="{
      'layout-card--header': type === `header`,
      'layout-card--rounded': rounded,
      'layout-card--header-top-sm-radius': type === `item-header`,
      'layout-card--shadow': shadow,
      'layout-card--primary': primary,
      'layout-card--hide': hideBackground,
    }"
    :style="layoutCardCustomStyles"
    :height="grow ? '100%' : 'auto'"
  >
    <div
      :class="{
        container: !largerContainer,
        'container-24': largerContainer,
        'container-compact': compact,
        'grow-container': grow,
      }"
    >
      <div v-if="loading" class="py-6 d-flex justify-center align-center">
        <v-progress-circular indeterminate color="black" />
      </div>
      <div
        v-else
        :class="{ 'layout-card--blur': blur, 'grow-inner-container': grow }"
      >
        <div
          v-if="title"
          class="d-flex justify-space-between align-center"
          :class="{ 'pb-3': !collapsable }"
          @click="collapsable ? toggleCollapse() : () => {}"
        >
          <div>
            <div v-if="preheading" class="tp-text-body grey--text">
              {{ preheading }}
            </div>
            <div class="tp-text-subtitle">{{ title }}</div>
            <div v-if="subtitle" class="tp-text-body grey--text">
              {{ subtitle }}
            </div>
          </div>

          <v-chip
            v-if="required"
            :ripple="false"
            color="#9E9E9E"
            class="white--text"
            small
          >
            {{ $t('required') }}
          </v-chip>
          <div v-if="collapsable">
            <v-icon>
              <template v-if="!collapsed">mdi-chevron-up</template>
              <template v-else>mdi-chevron-down</template>
            </v-icon>
          </div>
        </div>
        <v-expand-transition v-if="collapsable">
          <v-row v-show="!collapsed" no-gutters>
            <slot></slot>
          </v-row>
        </v-expand-transition>
        <slot v-else></slot>
      </div>
      <slot name="actions"></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: `item`,
      required: false,
    },
    title: {
      type: String,
      default: ``,
      required: false,
    },
    preheading: {
      type: String,
      default: ``,
      required: false,
    },
    subtitle: {
      type: String,
      default: ``,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    rounded: {
      type: Boolean,
      default: false,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    largerContainer: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    defaultCollapsed: {
      type: Boolean,
      default: true,
    },
    hideBackground: {
      type: Boolean,
      default: false,
    },
    useCustomBackgroundColor: {
      type: Boolean,
      default: false,
    },
    blur: {
      type: Boolean,
      default: false,
    },
    onToggleCollapse: {
      type: Function,
      default: () => {},
    },
    grow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: this.defaultCollapsed,
      layoutCardCustomStyles: this.useCustomBackgroundColor
        ? {
            background: `#FFFFFF`,
          }
        : {},
    };
  },
  mounted() {
    if (this.useCustomBackgroundColor) {
      const rotatingBackgroundColors = this.$store.getters
        .customizationMenuCategoriesSectionsRotatingBackgroundColors;

      if (rotatingBackgroundColors) {
        this.layoutCardCustomStyles.background =
          rotatingBackgroundColors[
            this.$vnode.key % rotatingBackgroundColors.length
          ] + `!important`;
      }

      const staticBackgroundColor = this.$store.getters
        .customizationMenuLayoutCardColor;
      if (staticBackgroundColor) {
        this.layoutCardCustomStyles.background = `${staticBackgroundColor}!important`;
      }

      const layoutCardRadius = this.$store.getters
        .customizationMenuLayoutCardRadius;
      if (layoutCardRadius) {
        this.layoutCardCustomStyles.borderRadius = `${layoutCardRadius}!important`;
      }

      const layoutCardBorderEnabled = this.$store.getters
        .customizationMenuLayoutCardBorderEnabled;
      const layoutCardBorderColor = this.$store.getters
        .customizationMenuLayoutCardBorderColor;
      if (layoutCardBorderEnabled) {
        let useBorderColor = layoutCardBorderColor
          ? layoutCardBorderColor
          : '#FFF';
        this.layoutCardCustomStyles.border = `1px solid ${useBorderColor}!important`;
      }
    }
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
      this.$emit('on-toggle-collapse', this.collapsed);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-card {
  background-color: $color-white;
  border-radius: 20px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &:last-child {
    border-radius: 20px 20px 0 0;
  }

  .required {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .grow-container {
    height: 100%;
  }

  .grow-inner-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .container-24 {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .container-compact {
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  &--header {
    border-radius: 0 0 20px 20px !important;
  }
  &--rounded {
    border-radius: 20px !important;
  }

  &--header-top-sm-radius {
    border-radius: 5px 5px 20px 20px !important;
  }
  &--shadow {
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 20px 20px;
  }
  &--primary {
    background-color: var(--color-primary);
  }
  &--hide {
    background-color: initial !important;
    border-radius: 0 !important;
  }
  &--blur {
    filter: blur(5px);
  }
}
</style>
