import Vue from 'vue';

import {
  OPERATION,
  generateCRN,
  ecrNameVersion,
} from '../../../utils/worldLine';

export default {
  state: {
    websocketUrl: localStorage.getItem('posServerIp') || 'ws://127.0.0.1:8001',
    unresolvedCRNs: JSON.parse(localStorage.getItem('unresolvedCRNs')) || [],
    unresolvedCRNsStatus: 'idle', // 'idle', 'loading', 'success', 'error
    terminalSocket: null,
    terminalOnline: false,
    pendingSettlementCRN: null,
    settlementStatus: 'idle', // 'idle', 'loading'
  },
  mutations: {
    addUnresolvedCRN(state, CRN) {
      state.unresolvedCRNs.push(CRN);
      localStorage.setItem(
        'unresolvedCRNs',
        JSON.stringify(state.unresolvedCRNs)
      );
    },
    removeUnresolvedCRN(state, CRN) {
      state.unresolvedCRNs = state.unresolvedCRNs.filter(item => item !== CRN);
      localStorage.setItem(
        'unresolvedCRNs',
        JSON.stringify(state.unresolvedCRNs)
      );
    },
    setTerminalSocket(state, socket) {
      state.terminalSocket = socket;
    },
    setTerminalSocketOnOpen(state, callback) {
      state.terminalSocket.onopen = callback;
    },
    setTerminalSocketOnMessage(state, callback) {
      state.terminalSocket.onmessage = callback;
    },
    setTerminalSocketOnClose(state, callback) {
      state.terminalSocket.onclose = callback;
    },
    setTerminalSocketOnError(state, callback) {
      state.terminalSocket.onerror = callback;
    },
    setTerminalOnline(state, online) {
      state.terminalOnline = online;
    },
    setUnresolvedCRNsStatus(state, status) {
      state.unresolvedCRNsStatus = status;
    },
    setPendingSettlementCRN(state, CRN) {
      state.pendingSettlementCRN = CRN;
    },
    setSettlementStatus(state, status) {
      state.settlementStatus = status;
    },
    setTerminalWebSocketUrl(state, url) {
      state.websocketUrl = url;
      localStorage.setItem('posServerIp', url);
    },
  },
  actions: {
    addUnresolvedCRN({ commit }, { CRN }) {
      commit('addUnresolvedCRN', CRN);
    },
    removeUnresolvedCRN({ commit }, { CRN }) {
      commit('removeUnresolvedCRN', CRN);
    },
    async subscribeToPosTerminal(
      { commit, getters, dispatch },
      { force = false } = {}
    ) {
      const terminalOnline = getters.terminalOnline;
      const websocketUrl = getters.terminalWebSocketUrl;

      if (terminalOnline && !force) {
        console.log('WORLDLINE: Already connected to terminal');
        return new Promise(resolve => {
          resolve();
        });
      }

      return new Promise(resolve => {
        if (!websocketUrl) {
          console.log('WORLDLINE: No websocket URL provided');
          return;
        }

        const connection = new WebSocket(websocketUrl);
        commit('setTerminalSocket', connection);

        const onOpen = () => {
          console.log('WORLDLINE: Socket opened');
          commit('setTerminalOnline', true);
          dispatch('closeToast');

          resolve();
        };
        const onMessage = event => {
          console.log('WORLDLINE: Socket message', event);
        };
        const onClose = () => {
          console.log('WORLDLINE: Socket closed');
          dispatch('destroyPosTerminal');
        };
        const onError = event => {
          console.log('WORLDLINE: Socket error', event);
          dispatch('destroyPosTerminal');
          dispatch('showToastInstantly', {
            title: Vue.i18n.translate(
              'kiosk.toasts.terminalConnectionError.title'
            ),
            description: Vue.i18n.translate(
              'kiosk.toasts.terminalConnectionError.description'
            ),
            isError: true,
            doNotClose: true,
          });
          resolve();
        };

        commit('setTerminalSocketOnOpen', onOpen);
        commit('setTerminalSocketOnMessage', onMessage);
        commit('setTerminalSocketOnClose', onClose);
        commit('setTerminalSocketOnError', onError);
      });
    },
    destroyPosTerminal({ state, commit }) {
      state?.terminalSocket?.close();
      commit('setTerminalSocket', null);
      commit('setTerminalOnline', false);
    },
    sendPosTerminalMessage({ getters }, { data, onError }) {
      const terminalSocket = getters.terminalSocket;
      try {
        console.log('WORLDLINE: sending message', JSON.stringify(data));
        terminalSocket.send(JSON.stringify(data));
      } catch (error) {
        console.error('WORLDLINE: Error sending message', error);
        if (onError) {
          onError();
        }
      }
    },
    setOnMessageCallback({ commit }, { callback }) {
      commit('setTerminalSocketOnMessage', callback);
    },
    setTerminalOffline({ commit, getters }) {
      const terminalSocket = getters.terminalSocket;
      terminalSocket?.close();
      commit('setTerminalOnline', false);
    },
    async voidUnresolvedCRNs({ getters, dispatch, commit }, { onVoided } = {}) {
      const unresolvedCRNs = getters.unresolvedCRNs;
      const hasUnresolvedCRNs = getters.hasUnresolvedCRNs;
      const printerOnline = getters.printerIsOnline;
      const terminalOnline = getters.terminalOnline;
      const voidPayment = CRN => {
        dispatch('voidPayment', { CRN });
      };

      if (hasUnresolvedCRNs && printerOnline && terminalOnline) {
        commit('setUnresolvedCRNsStatus', 'loading');

        for (let i = 0; i < unresolvedCRNs.length; i++) {
          if (!getters.terminalOnline) {
            console.log('WORLDLINE: Terminal offline in voidUnresolvedCRNs');
            break;
          }
          await new Promise(resolve => {
            setTimeout(() => {
              voidPayment(unresolvedCRNs[i]);
              resolve();
            }, 3000);
          });
        }
        return await new Promise(resolve => {
          setTimeout(() => {
            if (onVoided) {
              onVoided();
            }
            commit('setUnresolvedCRNsStatus', 'idle');
            resolve();
          }, 3000);
        });
      }
    },
    voidPayment({ dispatch }, { CRN }) {
      const data = {
        CRN,
        operation: OPERATION.VOID,
        reversalType: 'autoVoid',
        dlgDisabled: false,
        receiptLineLength: 40,
      };

      dispatch('sendPosTerminalMessage', { data });
    },
    async settleEndOfWorkDay({ dispatch, getters, commit }) {
      const isKiosk = getters.isKiosk;

      if (!isKiosk) {
        return;
      }

      await dispatch('subscribeToPosTerminal');

      const pendingSettlementCRN = getters.pendingSettlementCRN;
      const newCRN = generateCRN();
      const CRN = pendingSettlementCRN || newCRN;

      const data = {
        operation: OPERATION.SETTLEMENT,
        ecrNameVersion,
        CRN,
        dlgDisabled: false,
        receiptLineLength: 40,
      };

      commit('setPendingSettlementCRN', CRN);
      commit('setSettlementStatus', 'loading');

      const onmessage = async message => {
        const data = JSON.parse(message.data);
        if (data.operation === OPERATION.SETTLEMENT && data.result) {
          dispatch('saveSettlement', {
            data,
          });

          const onPrintSuccess = () => {
            commit('setPendingSettlementCRN', null); // IMPORTANT to reset the CRN after a successful settlement
            commit('setSettlementStatus', 'idle');

            dispatch('generateNewKioskSession');
          };
          const onPrinterError = () => {
            commit('setSettlementStatus', 'idle');
          };

          dispatch('printReceipt', {
            text: data.merchantReceipt,
            withLogo: false,
            onSuccess: onPrintSuccess,
            onError: onPrinterError,
          });

          return;
        } else if (!data.result) {
          commit('setSettlementStatus', 'idle');
        }
      };

      dispatch('setOnMessageCallback', { callback: onmessage });
      dispatch('sendPosTerminalMessage', { data });
    },
  },
  getters: {
    unresolvedCRNs: state => state.unresolvedCRNs,
    hasUnresolvedCRNs: state => state.unresolvedCRNs.length > 0,
    loadingUnresolvedCRNs: state => state.unresolvedCRNsStatus === 'loading',
    terminalOnline: state => state.terminalOnline,
    terminalSocket: state => state.terminalSocket,
    pendingSettlementCRN: state => state.pendingSettlementCRN,
    loadingPendingSettlement: state => state.settlementStatus === 'loading',
    terminalWebSocketUrl: state => state.websocketUrl,
  },
};
