var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutCard',{attrs:{"rounded":""}},[_c('div',{staticClass:"option-group"},[_c('div',[_c('div',{staticClass:"d-flex flex-row justify-space-between tp-text-caption"},[(
            _vm.$store.getters.functionalityOrderEnabled &&
              !this.$store.getters.ordersTemporarilyDisabled
          )?_c('div',{staticClass:"d-flex flex-column"},[(_vm.max_choices > 0 && !_vm.singleSelection)?_c('div',{staticClass:"mb-1",class:{ shake: _vm.shakeLabel }},[_vm._v(" "+_vm._s(_vm.optionLabel)+" ")]):_vm._e(),_c('div',{staticClass:"tp-text-subtitle"},[_vm._v(" "+_vm._s(_vm.$t(_vm.name))+" ")])]):_c('div',[(_vm.max_choices > 1 && !_vm.singleSelection)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('components.options.can_choose_up_to_x_options', { amount: _vm.max_choices, }))+" ")]):_vm._e(),(_vm.max_choices < 2 && !_vm.singleSelection)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('components.options.can_choose_up_to_one_option', { amount: _vm.max_choices, }))+" ")]):_vm._e(),_c('div',{staticClass:"tp-text-subtitle"},[_vm._v(" "+_vm._s(_vm.$t(_vm.name))+" ")])]),_c('div',[(_vm.type === 'single' || _vm.min_choices > 0)?_c('v-chip',{staticClass:"d-flex chip tp-text-label",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$t('components.options.required'))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"mt-6"},[(_vm.singleSelection)?_c('v-radio-group',{staticClass:"mt-0 pt-0",attrs:{"hide-details":""},model:{value:(_vm.radioSelected),callback:function ($$v) {_vm.radioSelected=$$v},expression:"radioSelected"}},_vm._l((_vm.getOptions),function(option,index){return _c('Option',_vm._b({key:`option-${index}`,attrs:{"single-selection":""}},'Option',{
            ...option,
            displayFullPrice: _vm.display_full_price,
            menuItemPrice: _vm.menuItemPrice,
            menuItemLoyaltyPrice: _vm.menuItemLoyaltyPrice,
            isCategoryItemsAvailable: _vm.isCategoryItemsAvailable,
          },false))}),1):_vm._l((_vm.getOptions),function(option,index){return _c('Option',_vm._b({key:`option-${index}`,attrs:{"max-selections-reached":_vm.maxSelectionsReached},on:{"maxSelectionsReached":_vm.shakeLabelForOption},model:{value:(_vm.optionsSelection[index]),callback:function ($$v) {_vm.$set(_vm.optionsSelection, index, $$v)},expression:"optionsSelection[index]"}},'Option',{ ...option, isCategoryItemsAvailable: _vm.isCategoryItemsAvailable },false))})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }