<template>
  <div v-show="visible" class="menu-controls">
    <div v-if="showPayGo" class="flex">
      <Button
        block
        data-cy="pay-go-button-cta"
        :dark="!isPlaceOrderButtonWhite"
        :white="isPlaceOrderButtonWhite"
        :background-color="
          this.$store.getters.customizationMenuPlaceOrderButtonBackgroundColor
        "
        @click="navigateToPayGo"
      >
        <span
          :style="{
            color: this.$store.getters
              .customizationMenuPlaceOrderButtonTextColor,
          }"
          >{{ $t('screens.menu.payBillCta') }}</span
        >
      </Button>
    </div>
    <div
      v-if="$store.getters.functionalityOrderEnabled"
      class="flex d-flex justify-between"
      style="gap: 1em"
    >
      <!-- Button for going to order list -->
      <Button
        v-if="isViewOrderButtonActive"
        @click="navigateToOrders"
        style="width: 33%"
      >
        <div class="place-order-button-content tp-text-subtitle-semibold-white">
          {{ getGoToOrdersButtonText }}
          <img :src="require(`@/assets/images/icons/food-icon.svg`)" />
        </div>
      </Button>

      <!-- Button for placing order -->
      <Button
        v-if="isPlaceOrderButtonActive"
        :dark="!isPlaceOrderButtonWhite"
        :white="isPlaceOrderButtonWhite"
        :background-color="
          this.$store.getters.customizationMenuPlaceOrderButtonBackgroundColor
        "
        :text-color="
          this.$store.getters.customizationMenuPlaceOrderButtonTextColor
        "
        data-cy="place-order-button"
        @click="navigateToPlaceOrder"
      >
        <div
          class="place-order-button-content"
          :class="{
            'place-order-button-content--dark-text': isPlaceOrderButtonWhite,
          }"
        >
          <div
            class="place-order-button-content__label tp-text-subtitle-semibold-white"
            :style="{
              color:
                this.$store.getters.customizationMenuPlaceOrderButtonTextColor +
                '!important',
            }"
          >
            {{ $t('screens.menu.viewOrderCtaLabel') }}
          </div>
          <div class="place-order-button-content__cart-count">
            {{ $store.getters.cartCount }}
          </div>
        </div>
      </Button>
      <Button
        v-else-if="
          $store.getters.ordersTemporarilyDisabled &&
            $store.getters.functionalityOrderEnabled &&
            !$store.getters.functionalityPayGoEnabled
        "
        dark
        disabled
        center
      >
        {{ getOrdersTemporaryDisabledButtonText }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
export default {
  components: {
    Button,
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    isSingleControl() {
      return !this.isViewOrderButtonActive || !this.isPlaceOrderButtonActive;
    },
    isOnlySingleActiveOrder() {
      return (
        this.$store.getters.ordersCount === 1 &&
        this.$store.getters.activeOrdersCount === 1
      );
    },
    isViewOrderButtonActive() {
      return this.$store.getters.ordersCount > 0;
    },
    isPlaceOrderButtonActive() {
      return (
        this.$store.getters.cartCount >= 0 &&
        !this.$store.getters.ordersTemporarilyDisabled
      );
    },
    isOrdersDisabledButtonActive() {
      return this.$store.getters.ordersTemporarilyDisabled;
    },
    getGoToOrdersButtonText() {
      if (this.isPlaceOrderButtonActive || this.isOrdersDisabledButtonActive) {
        if (this.isOnlySingleActiveOrder) {
          return this.$t('screens.menu.singleActiveOrderSmallBtn');
        } else if (this.$store.getters.activeOrdersCount > 0) {
          return this.$t('screens.menu.activeOrdersSmallBtn');
        } else {
          return this.$t('screens.menu.completedOrdersSmallBtn');
        }
      } else {
        if (this.isOnlySingleActiveOrder) {
          return this.$t('screens.menu.singleActiveOrderLargeBtn');
        } else if (this.$store.getters.activeOrdersCount > 0) {
          return this.$t('screens.menu.activeOrdersLargeBtn');
        } else {
          return this.$t('screens.menu.completedOrdersLargeBtn');
        }
      }
    },
    getOrdersTemporaryDisabledButtonText() {
      if (this.isViewOrderButtonActive) {
        return this.$t('screens.menu.ordersDisabledShortCtaLabel');
      } else {
        return this.$t('screens.menu.ordersDisabledCtaLabel');
      }
    },
    showPayGo() {
      return (
        this.$store.getters.functionalityPayGoEnabled &&
        !this.$store.getters.functionalityOrderEnabled
      );
    },
    isPlaceOrderButtonWhite() {
      return this.$store.getters.customizationMenuPlaceOrderButtonWhite;
    },
    orderEnabled() {
      return this.$store.getters.functionalityOrderEnabled;
    },
    visible() {
      return this.showPayGo || this.orderEnabled;
    },
  },
  methods: {
    navigateToPlaceOrder() {
      this.$router.push({
        name: 'PlaceOrder',
        params: { session: this.session },
      });

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'navigate_to_view_order',
      });
    },
    navigateToOrders() {
      if (
        this.$store.getters.ordersCount === 1 &&
        this.$store.getters.activeOrdersCount === 1
      ) {
        const orderNumber = this.$store.state.orders[0].unique_order_number;
        this.$store.dispatch('openOrder', orderNumber);
      } else {
        this.$router.push({
          name: 'Orders',
          params: { session: this.session },
        });
      }

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'navigate_to_view_orders',
      });
    },
    navigateToPayGo() {
      const orderNumber = this.$store.getters.getFirstPOSOpenOrderId;
      let params = {
        name: 'Order',
        params: { session: this.session },
      };
      if (orderNumber) {
        params.query = { orderNumber };
      }
      this.$router.push(params);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-controls {
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  padding: 2em 1em calc(2em + env(safe-area-inset-bottom)) 1em;
  background-image: linear-gradient(
    to top,
    var(--menu-background-color, white) 0%,
    var(--menu-background-color, white) 30%,
    rgba(0, 212, 255, 0) 100%
  );

  @media screen and (min-width: $max-app-width) {
    max-width: $max-app-width;
  }
}
</style>
